import { isLocal } from "./env";

type StartSettings = {
  expanded: boolean; // Whether the chat is expanded or not
  hover: boolean; // Whether the chat is in hover-state or not
};

// Define the settings for the dev mode and the normal mode
const startSettings: { dev: StartSettings; normal: StartSettings } = {
  dev: {
    expanded: true,
    hover: true,
  },
  normal: {
    expanded: false,
    hover: false,
  },
};

// Define the type for the settings
export const settings: StartSettings = isLocal
  ? startSettings.dev
  : startSettings.normal;
