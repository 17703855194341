/**
 * Escapes periods after numbers in Markdown list items to prevent
 * misinterpretation by Markdown parsers (e.g., turning '- 100. test' into '- 100\. test').
 *
 * This function handles both ordered and unordered lists, including nested lists
 * with varying levels of indentation.
 *
 * @param text - The Markdown text to be processed.
 * @returns The modified text with escaped periods after numbers in list items.
 */
export function fixLLMLists(text: string): string {
  return text.replace(
    /^(\s*(?:[\*\-\+]|(?:\d+\.))\s+)(\d+)\.(.*)$/gm,
    "$1$2\\.$3",
  );
}

/**
 * Applies necessary fixes to Markdown text that may be introduced by LLMs.
 *
 * @param text - The Markdown text to be processed.
 * @returns The modified text with all applied fixes.
 */
export function fixLLMMarkdown(text: string): string {
  return fixLLMLists(text);
}
