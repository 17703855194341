import React from "react";
import ReactDOM from "react-dom/client";
import CityGPT from "~/src/components/index";
import { DEFAULT_LANGUAGE } from "~/src/config/env";

import { PUBLISH_URL, STARTUP_MESSAGE } from "~/src/config/env";

(function () {
  try {
    class CityGPTElement extends HTMLElement {
      private root: ShadowRoot;

      constructor() {
        super();
        this.root = this.attachShadow({ mode: "open" });
      }

      static get observedAttributes() {
        return ["org"];
      }

      connectedCallback() {
        this.render();
      }

      attributeChangedCallback() {
        this.render();
      }

      render() {
        const lang = this.getAttribute("lang") || DEFAULT_LANGUAGE;

        // Clear the shadow root
        while (this.root.firstChild) {
          this.root.removeChild(this.root.firstChild);
        }

        // Attach external stylesheet
        const linkElem = document.createElement("link");
        linkElem.setAttribute("rel", "stylesheet");
        linkElem.setAttribute("href", `${PUBLISH_URL}/chatbot-gpt.css`);

        this.root.appendChild(linkElem);

        // Create a container div for React to render into
        const container = document.createElement("div");
        container.classList.add("chatbot-gpt");
        this.root.appendChild(container);

        ReactDOM.createRoot(container).render(<CityGPT lang={lang} />);
      }
    }

    // Define the primary custom element
    customElements.define("chatbot-gpt", CityGPTElement);

    // Wrapper class for the deprecated tag name
    class DeprecatedCityGPTElement extends CityGPTElement {}

    // Define the deprecated custom element
    customElements.define("city-gpt", DeprecatedCityGPTElement);

    if (document.querySelector("city-gpt")) {
      console.warn(
        `<city-gpt /> is deprecated. Please use <chatbot-gpt /> instead. See documentation: ${PUBLISH_URL}.`,
      );
    }

    // Check if there is already a <chatbot-gpt> element on the page
    if (!document.querySelector("chatbot-gpt")) {
      // If no <chatbot-gpt> element exists, add one at the end of the document body
      const cityGptElement = document.createElement("chatbot-gpt");
      document.body.appendChild(cityGptElement);
    }

    // Create a link element for the fonts CSS
    const fontLinkElem = document.createElement("link");
    fontLinkElem.setAttribute("rel", "stylesheet");
    fontLinkElem.setAttribute("href", `${PUBLISH_URL}/chatbot-gpt-fonts.css`);
    fontLinkElem.setAttribute("id", "chatbot-gpt-fonts");

    // Append it to the document head
    document.head.appendChild(fontLinkElem);

    console.log(STARTUP_MESSAGE);
  } catch (error) {
    console.error("An error occurred during CityGPT startup:", error);
  }
})();
