type LiipGPTClientOptions = {
  apiUrl: string;
  apiKey?: string;
};

export type PredefinedQuestion = {
  label: string | null;
  question: string;
};

type PredefinedQuestionsParams = {
  url: string;
  language: string;
};

export class LiipGPTClient {
  private readonly apiUrl: string;
  private readonly apiKey: string;

  constructor({ apiUrl, apiKey = "X9hL4Gp5W2D7eRtF" }: LiipGPTClientOptions) {
    this.apiUrl = apiUrl;
    this.apiKey = apiKey;
  }

  private async fetchData(path: string, params: Record<string, string> = {}) {
    const response = await fetch(
      `${this.apiUrl}${path}?${new URLSearchParams(params).toString()}`,
      {
        headers: {
          "api-key": this.apiKey,
        },
      },
    );

    if (response.ok) {
      return response.json();
    } else {
      throw new Error("Failed to fetch data");
    }
  }

  async getPredefinedQuestions(
    params: PredefinedQuestionsParams,
  ): Promise<PredefinedQuestion[]> {
    const data = await this.fetchData("/topics/questions", params);

    return data.predefinedQuestions;
  }
}
