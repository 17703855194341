import React, {
  Dispatch,
  SetStateAction,
  useState,
  ForwardedRef,
  forwardRef,
} from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface PromptFormProps {
  setInputValue: Dispatch<SetStateAction<string>>;
  inputValue: string;
  sendSubmit: (value: string) => void;
  submitDisabled: boolean;
  isLoading: boolean;
  ref: React.Ref<HTMLInputElement>;
}

export const PromptForm = forwardRef<HTMLInputElement, PromptFormProps>(
  (
    { setInputValue, inputValue, sendSubmit, submitDisabled, isLoading },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const { t } = useTranslation();

    const handleSubmit = (event: React.FormEvent) => {
      event.preventDefault();
      if (submitDisabled || isLoading) {
        return;
      }

      sendSubmit(inputValue);

      // Blur the input to hide the mobile keyboard
      if (ref && "current" in ref && ref.current) {
        ref.current.blur();
      }
    };

    return (
      <form className="prompt-form" onSubmit={handleSubmit}>
        <label htmlFor="question" className="sr-only">
          {t("chat.question.label")}
        </label>
        <input
          ref={ref}
          className={classNames("input", "prompt-form__input")}
          placeholder={t("chat.question.placeholder", { defaultValue: "" })}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              if (
                !document.activeElement?.getAttribute("aria-activedescendant")
              ) {
                handleSubmit(event);
              }
            }
          }}
        />

        <button
          type="submit"
          className={classNames(
            "prompt-form__button",
            "button",
            "button--primary",
          )}
          disabled={submitDisabled || isLoading}
        >
          <span className="sr-only">{t("chat.question.buttonText")}</span>
        </button>
      </form>
    );
  },
);
