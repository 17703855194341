import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ClassificationModule } from "./module.classification";
import { FormModule } from "./module.form";
import { VoteModule } from "./module.vote";

export type ModuleName = "classification" | "form" | "vote";

interface FeedbackProps {
  requestId: string | null;
  inputValue: string;
  userId: string | null;
  modules: ModuleName[];
}

export enum ValidationStatus {
  INIT = "INIT",
  VALID = "VALID",
  INVALID = "INVALID",
}

interface ModuleStatus {
  status: ValidationStatus;
  sendData?: () => Promise<void>;
}

export const Feedback: React.FC<FeedbackProps> = ({
  requestId,
  userId,
  inputValue,
  modules: initialModules,
}) => {
  const { t } = useTranslation();

  // Manage modules as state to allow dynamic updates
  const [modules, setModules] = useState<ModuleName[]>(initialModules);
  const [moduleStatuses, setModuleStatuses] = useState<
    Record<ModuleName, ModuleStatus>
  >({} as Record<ModuleName, ModuleStatus>);
  const [statusMessage, setStatusMessage] = useState<string>("INIT");

  const updateModuleStatus = (
    moduleName: ModuleName,
    status: { status: ValidationStatus; sendData?: () => Promise<void> },
  ) => {
    setModuleStatuses((prevStatuses) => ({
      ...prevStatuses,
      [moduleName]: status,
    }));
  };

  const handleSend = async () => {
    // Check if all modules are valid
    const allValid = Object.values(moduleStatuses).every(
      (status) =>
        status.status === ValidationStatus.VALID ||
        status.status === ValidationStatus.INIT,
    );

    if (!allValid) {
      setStatusMessage("ERROR");
      return;
    }

    try {
      // Each module sends its own data
      setStatusMessage("SENDING");
      // Wait for all modules to finish sending
      await Promise.all(
        Object.values(moduleStatuses).map((status) => {
          if (status.sendData && status.status === ValidationStatus.VALID) {
            return status.sendData();
          }
          return Promise.resolve();
        }),
      );
      setStatusMessage("OK");
    } catch (error) {
      console.error("Error sending feedback:", error);
      setStatusMessage("ERROR");
    }
  };

  const definedModules = Object.values(moduleStatuses).filter(
    (status) => status.status !== ValidationStatus.INIT,
  );

  return (
    <>
      {statusMessage === "INIT" && (
        <>
          {!modules.includes("vote") && (
            <>
              <hr />
              <h1>{t("feedback.title")}</h1>
            </>
          )}
          {modules.includes("vote") && (
            <VoteModule
              requestId={requestId}
              setModules={setModules}
              setStatusMessage={setStatusMessage}
            />
          )}
          {modules.includes("classification") && (
            <ClassificationModule
              requestId={requestId}
              updateModuleStatus={updateModuleStatus}
            />
          )}
          {modules.includes("form") && (
            <FormModule
              requestId={requestId}
              inputValue={inputValue}
              userId={userId}
              updateModuleStatus={updateModuleStatus}
            />
          )}
        </>
      )}

      {statusMessage === "INIT" && Object.keys(moduleStatuses).length > 0 && (
        <div className="feedback__footer">
          <button
            disabled={
              definedModules.length === 0 ||
              !definedModules.every(
                (status) => status.status === ValidationStatus.VALID,
              )
            }
            className="button button--primary"
            onClick={handleSend}
          >
            <span>{t("feedback.send")}</span>
          </button>
        </div>
      )}
      {statusMessage !== "INIT" && (
        <div className="feedback__footer">
          {statusMessage === "SENDING" && <h2>{t("feedback.sending")}</h2>}
          {statusMessage === "OK" && <h2>{t("feedback.feedbackReceived")}</h2>}
          {statusMessage === "ERROR" && (
            <h2>{t("feedback.feedbackReceivedError")}</h2>
          )}
        </div>
      )}
    </>
  );
};
