import React from "react";
import classNames from "classnames";

type LinkBubbleProps = {
  children: React.ReactNode;
  position?: "left" | "center" | "right";
};

export const LinkBubble = ({
  children,
  position = "center",
}: LinkBubbleProps) => {
  const bubbleClasses = classNames("link-bubble", {
    "link-bubble--left": position === "left",
    "link-bubble--center": position === "center",
    "link-bubble--right": position === "right",
  });

  return (
    <span className={bubbleClasses}>
      <span className="link-bubble__inner">{children}</span>
    </span>
  );
};
