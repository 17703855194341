/**
 * Splits a string into a key-value pair at the first colon (":").
 * The key is everything before the first colon, and the value is everything after.
 * If no colon is found, the entire string is returned as the value with an empty key.
 *
 * @param input - The input string to split.
 * @returns A tuple where the first element is the key and the second is the value.
 */
export function splitKeyValue(input: string): [string, string] {
  const index = input.indexOf(":");
  if (index === -1) {
    // Return the whole string as the key if no ":" is found
    return ["", input];
  }

  const key = input.substring(0, index).trim();
  const value = input.substring(index + 1).trim();
  return [key, value];
}
