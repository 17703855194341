import { useMemo } from "react";
import IconArrowRight from "jsx:~/src/components/_root/assets/arrow-right.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSources } from "~src/context/SourcesContext";
import { SourcesDto } from "~/src/components/Chat/helpers";
import { getTitle } from "~/src/util/getTitle";
import { splitArrayByIndexes } from "./splitArrayByIndexes";

enum SearchResultType {
  PDF = "pdf",
}

const renderLink = (result: SourcesDto) => {
  switch (result.type) {
    case SearchResultType.PDF:
      return (
        <a
          className="sources__link"
          href={result.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="sources__prefix" />
          <IconArrowRight />
          <span className="sources__link-title">{result.title}</span>
        </a>
      );
    default:
      return (
        <a className="sources__link" href={result.url} target="_blank">
          <span className="sources__prefix" />
          <IconArrowRight />
          <span className="sources__link-title">{getTitle(result?.title)}</span>
        </a>
      );
  }
};

const Sources: React.FC = () => {
  const { t } = useTranslation();
  const { state } = useSources();

  const consideredSearchResults: SourcesDto[] = useMemo(() => {
    return state.sources.filter((result) => result.considered);
  }, [state.sources]);

  const { excluded, included } = splitArrayByIndexes(
    consideredSearchResults,
    state.usedSources.map((index) => parseInt(index, 10)),
  );

  if (!consideredSearchResults.length) {
    return null;
  }

  return (
    <div className="sources">
      {consideredSearchResults.length > 0 && (
        <div className={classNames("sources__box")}>
          <>
            <h2>{t("chat.answer.usedSourcesTitle")}</h2>
            <ol className="sources__list">
              {included.map((result, index) => (
                <li key={index} className="sources__list-item">
                  {renderLink(result)}
                </li>
              ))}
              {excluded.slice(0, 3).map((result, index) => (
                <li key={index} className="sources__list-item">
                  {renderLink(result)}
                </li>
              ))}
            </ol>
          </>
        </div>
      )}
    </div>
  );
};

export default Sources;
