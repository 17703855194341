import { visit } from "unist-util-visit";
import type { Plugin } from "unified";
import type { Node, Parent } from "unist";
import { Text } from "mdast";
import { FootnoteLink } from "./FootnoteLink";

// Can also process stuff like [^1,^2] [^1, ^2]
const FOOTNOTES_REGEX = /\[\^([\^0-9, ]+)]/g;

const footnotePlugin: Plugin = () => {
  return (tree: Node) => {
    visit(
      tree,
      "text",
      (node: Node, index: number | null, parent: Parent | null) => {
        if (!parent || !("children" in parent)) {
          // Ensure parent is not null and has children
          return;
        }

        if (!("value" in node) || typeof node.value !== "string") {
          return;
        }

        const { value } = node;
        const matches = [...value.matchAll(FOOTNOTES_REGEX)];

        if (matches.length === 0) return;

        const newNodes: Node[] = [];
        let lastIndex = 0;

        matches.forEach((match) => {
          const [fullMatch, numbers] = match;
          const matchIndex = match.index as number;

          const footnoteNumbers = numbers
            .split(/[,\^ ]/)
            .map((num) => num.trim()) // Remove the ^ character and trim spaces
            .filter(Boolean); // Remove empty strings

          // Add text before the match
          if (matchIndex > lastIndex) {
            const textNode: Text = {
              type: "text",
              value: value.slice(lastIndex, matchIndex),
            };
            newNodes.push(textNode);
          }

          // Add custom footnoteLink node
          const footnoteLink: FootnoteLink = {
            type: "footnoteLink",
            data: {
              hName: "footnoteLink",
              hProperties: { value: footnoteNumbers },
            },
          };
          newNodes.push(footnoteLink);

          lastIndex = matchIndex + fullMatch.length;
        });

        // Add remaining text after the last match
        if (lastIndex < value.length) {
          const textNode: Text = {
            type: "text",
            value: value.slice(lastIndex),
          };
          newNodes.push(textNode);
        }

        // Replace the original text node with the new nodes
        parent.children.splice(index as number, 1, ...newNodes);
      },
    );
  };
};

export default footnotePlugin;
