import React from "react";
import { useTranslation } from "react-i18next";
import IconPositive from "jsx:~/src/components/Feedback/assets/positive.svg";
import IconNegative from "jsx:~/src/components/Feedback/assets/negative.svg";
import { ModuleName } from "~/src/components/Feedback/index";
import { BACKEND_URL } from "~src/config/env";

interface VoteModuleProps {
  requestId: string | null;
  setModules: React.Dispatch<React.SetStateAction<ModuleName[]>>;
  setStatusMessage: React.Dispatch<React.SetStateAction<string>>;
}

enum FeedbackVote {
  Positive = "positive",
  Negative = "negative",
}

export const VoteModule: React.FC<VoteModuleProps> = ({
  requestId,
  setModules,
  setStatusMessage,
}) => {
  const { t } = useTranslation();

  const handleVote = async (vote: FeedbackVote) => {
    try {
      const body = {
        feedback: vote,
        rID: requestId || "",
      };

      const response = await fetch(`${BACKEND_URL}/quickfeedback`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await response.text();
      if (data !== "OK") {
        throw new Error("Quick feedback submission failed");
      }

      if (vote === FeedbackVote.Positive) {
        // show thank you message
        setStatusMessage("OK");
      } else {
        // display the form module
        setModules((prevModules) => [...prevModules, "form"]);
      }
    } catch (error) {
      console.error("Error sending quick feedback:", error);
      setStatusMessage("ERROR");
    }
  };

  return (
    <div className="feedback__vote">
      <button
        className="feedback__icon feedback__icon--positive"
        onClick={() => handleVote(FeedbackVote.Positive)}
      >
        <IconPositive />
        <span className="feedback__icon-text">
          {t("feedback.vote.positive")}
        </span>
      </button>
      <button
        className="feedback__icon feedback__icon--negative"
        onClick={() => handleVote(FeedbackVote.Negative)}
      >
        <IconNegative />
        <span className="feedback__icon-text">
          {t("feedback.vote.negative")}
        </span>
      </button>
    </div>
  );
};
