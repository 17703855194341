import React, {
  createContext,
  useReducer,
  useContext,
  Dispatch,
  ReactNode,
} from "react";
import { SourcesDto } from "~/src/components/Chat/helpers";
// import { data } from "~/src/components/Chat/debug";

// Define the action types
type Action =
  | { type: "ADD_SOURCE"; payload: SourcesDto }
  | { type: "RESET_SOURCES" }
  | { type: "SET_USED_SOURCES"; payload: string[] };

// Initial state
interface InitialState {
  sources: SourcesDto[];
  usedSources: string[];
}

const initialState: InitialState = {
  sources: /* data.sources || */ [],
  usedSources: [],
};

// Reducer function
const sourcesReducer = (state: InitialState, action: Action): InitialState => {
  switch (action.type) {
    case "ADD_SOURCE":
      return { ...state, sources: [...state.sources, action.payload] };
    case "RESET_SOURCES":
      return { ...state, sources: [], usedSources: [] };
    case "SET_USED_SOURCES":
      return { ...state, usedSources: Array.from(new Set(action.payload)) };
    default:
      return state;
  }
};

// Create context with proper types
interface SourcesContextType {
  state: InitialState;
  dispatch: Dispatch<Action>;
}

const SourcesContext = createContext<SourcesContextType>({
  state: initialState,
  dispatch: () => null,
});

// Context Provider
interface SourcesProviderProps {
  children: ReactNode;
}

export const SourcesProvider: React.FC<SourcesProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(sourcesReducer, initialState);

  return (
    <SourcesContext.Provider value={{ state, dispatch }}>
      {children}
    </SourcesContext.Provider>
  );
};

// Custom hook for using the context
export const useSources = (): SourcesContextType => useContext(SourcesContext);
