import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { ValidationStatus } from "~/src/components/Feedback/index";
import { BACKEND_URL } from "~src/config/env";

type CurrentModuleName = "form";

interface FormModuleProps {
  requestId: string | null;
  inputValue: string;
  userId: string | null;
  updateModuleStatus: (
    moduleName: CurrentModuleName,
    status: { status: ValidationStatus; sendData?: () => Promise<void> },
  ) => void;
}

export const FormModule: React.FC<FormModuleProps> = ({
  requestId,
  inputValue,
  userId,
  updateModuleStatus,
}) => {
  const { t } = useTranslation();

  const [feedbackValue, setFeedbackValue] = useState<string>("");
  const [emailValue, setEmailValue] = useState<string>(
    sessionStorage.getItem("email") || "",
  );

  // Update validation status whenever feedbackValue changes
  useEffect(() => {
    if (!feedbackValue) {
      updateModuleStatus("form", {
        status: ValidationStatus.INIT,
      });
      return;
    }

    updateModuleStatus("form", {
      status: ValidationStatus.VALID,
      sendData: sendFeedback,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feedbackValue, emailValue]);

  const sendFeedback = async () => {
    const body: Record<string, string | undefined> = {
      query: inputValue || "",
      email: emailValue,
      body: feedbackValue,
      rID: requestId || undefined,
      uID: userId || "",
    };
    sessionStorage.setItem("email", emailValue);

    const response = await fetch(`${BACKEND_URL}/feedback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.text();
    if (data !== "OK") {
      throw new Error("Feedback submission failed");
    }
  };

  return (
    <div>
      <div>
        <label
          className={classNames("h2", "feedback__title")}
          htmlFor="feedback"
        >
          {t("feedback.messageLabel")}
        </label>
        <textarea
          className={classNames("input", "feedback__input")}
          id="feedback"
          value={feedbackValue}
          onChange={(event) => setFeedbackValue(event.target.value)}
        ></textarea>
      </div>
      <div>
        <label className={classNames("h2", "feedback__title")} htmlFor="email">
          {t("feedback.emailLabel")}
        </label>
        <input
          className={classNames("input", "feedback__input")}
          id="email"
          value={emailValue}
          onChange={(event) => setEmailValue(event.target.value)}
        />
      </div>
    </div>
  );
};
