import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { SourcesDto } from "~/src/components/Chat/helpers";
import { LinkBubble } from "~/src/components/LinkBubble/index";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeExternalLinks from "rehype-external-links";
import { FootnoteLink } from "~/src/components/Markdown/plugins/FootnoteLink";
import { getTitle } from "~/src/util/getTitle";

const Link = (node: {
  className?: string;
  href?: string;
  title?: string;
  children: React.ReactNode;
  rel?: string;
  target?: string;
}) => {
  return (
    <a
      className={classNames(["a--cta", node.className])}
      href={node.href}
      target={node.target}
      rel={node.rel}
    >
      {node.children}
    </a>
  );
};

type FootnoteLinkComponentProps = {
  number: number;
  link: SourcesDto;
  containerRef: React.RefObject<HTMLElement>; // Reference to the other box
};

const showGeneratedSummary = (result: {
  description?: string;
  summary?: string;
  type?: string | null | undefined;
}) => {
  if (!!result.summary && result.type === "pdf") {
    return true;
  }
  return (
    (!result.description || result.description.length < 140) && !!result.summary
  );
};

export const FootnoteLinkComponent = ({
  number,
  link,
  containerRef,
}: FootnoteLinkComponentProps) => {
  const [position, setPosition] = useState<"left" | "right" | "center">(
    "center",
  );
  const footnoteLinkRef = useRef<HTMLSpanElement>(null);

  const handleMouseEnter = () => {
    if (containerRef.current && footnoteLinkRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const footnoteRect = footnoteLinkRef.current.getBoundingClientRect();

      // if footnote has left and right space inside container center the position
      if (
        footnoteRect.left > containerRect.left &&
        footnoteRect.right < containerRect.right
      ) {
        setPosition("center");
      } else if (footnoteRect.left < containerRect.left) {
        setPosition("left");
      } else {
        setPosition("right");
      }
    } else {
      setPosition("center");
    }
  };

  const handleMouseLeave = () => {
    setTimeout(() => {
      setPosition("center");
    }, 450);
  };

  return (
    <span
      className="source-link"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Link
        className="a--nowrap source-link__anchor"
        href={link.url}
        target="_blank"
        rel="noreferrer"
      >
        <span>Link {number}</span>
      </Link>
      <span
        ref={footnoteLinkRef}
        className={classNames("source-link__box", {
          "source-link__box--left": position === "left",
          "source-link__box--right": position === "right",
        })}
      >
        <LinkBubble position={position}>
          <span className="source-link__title">{getTitle(link.title)}</span>
          <span className="source-link__content">
            {link.description && (
              <span className="description">{link.description}</span>
            )}
            {showGeneratedSummary(link) && <span>{link.summary}</span>}
          </span>
        </LinkBubble>
      </span>
    </span>
  );
};

export const chatRenderers = (
  sources: SourcesDto[],
  usedSources: string[],
  parentRef: React.RefObject<HTMLElement>,
) => {
  const components = {
    a: Link,

    footnoteLink: ({ node }: { node: any }) => {
      const indices = node?.properties.value;

      if (!Array.isArray(indices) || indices.length === 0) {
        return null;
      }

      const links = indices.map((index) => {
        const link = sources[index - 1];
        if (!link) return null;

        return (
          <FootnoteLinkComponent
            key={index}
            number={1 + usedSources.indexOf(`${index}`)}
            link={link}
            containerRef={parentRef}
          />
        );
      });

      // Reduce the filtered links to format them with commas
      const output = links.reduce((acc, link, idx) => {
        if (idx === 0) {
          return link;
        }
        return (
          <>
            {acc}, {link}
          </>
        );
      }, null);

      return (
        <>
          <span> (</span>
          {output}
          <span>)</span>
        </>
      );
    },

    img: ({
      src,
      alt,
      title,
    }: {
      src?: string;
      alt?: string;
      title?: string;
    }) => <img src={src} alt={alt} title={title} loading="lazy" />,
  };

  return components;
};
