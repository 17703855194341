import { visit } from "unist-util-visit";
import type { Plugin } from "unified";
import type { Node } from "unist";
import { FootnoteLink } from "./FootnoteLink";

const combineFootnotesPlugin: Plugin = () => {
  return (tree: Node) => {
    const processNode = (node: Node) => {
      if ("children" in node && Array.isArray(node.children)) {
        const children = node.children;
        let i = 0;

        while (i < children.length) {
          const child = children[i];

          if (child.type === "footnoteLink") {
            const combinedValues = new Set<string>();
            let j = i;

            // Collect footnoteLink nodes and intervening whitespace text nodes
            while (j < children.length) {
              const currentNode = children[j];

              if (currentNode.type === "footnoteLink") {
                const footnoteLinkNode = currentNode as FootnoteLink;
                footnoteLinkNode.data.hProperties.value.forEach((v) =>
                  combinedValues.add(v),
                );
                j++;
              } else if (
                currentNode.type === "text" &&
                /^\s*$/.test(currentNode.value)
              ) {
                // Text node with only whitespace
                j++;
              } else {
                // Encountered a node that's neither a footnoteLink nor whitespace
                break;
              }
            }

            // Sort the values numerically
            const sortedValues = Array.from(combinedValues).sort(
              (a, b) => Number(a) - Number(b),
            );

            // Create a new footnoteLink node with sorted unique values
            const newFootnoteLink: FootnoteLink = {
              type: "footnoteLink",
              data: {
                hName: "footnoteLink",
                hProperties: {
                  value: sortedValues,
                },
              },
            };

            // Replace the sequence with the new node
            children.splice(i, j - i, newFootnoteLink);

            // Move to the next node after the combined one
            i++;
          } else {
            // Recursively process child nodes
            processNode(child);
            i++;
          }
        }
      }
    };

    processNode(tree);
  };
};

export default combineFootnotesPlugin;
