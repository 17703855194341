import classNames from "classnames";

interface ExpandDimensionProps {
  children: React.ReactNode;
  className?: string;
  expandDirection?: "x" | "y"; // New prop to control expansion direction
  forceExpanded?: boolean;
}

const ExpandDimension: React.FC<ExpandDimensionProps> = ({
  children,
  className,
  expandDirection,
  forceExpanded,
}) => {
  return (
    <div
      className={classNames(className, "expand-dimension", {
        ["expand-dimension--force-expanded"]: forceExpanded,
        ["expand-dimension--x"]: expandDirection === "x",
        ["expand-dimension--y"]: expandDirection === "y",
      })}
    >
      <div className="expand-dimension__inner">{children}</div>
    </div>
  );
};

export default ExpandDimension;
