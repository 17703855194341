import React, { useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useSources } from "~src/context/SourcesContext";
import { chatRenderers } from "./chatRenderers";
import { fixLLMMarkdown } from "./fixLLMMarkdown";
import ReactMarkdown from "react-markdown";
import "property-information"; // Workaround for dev-build; see https://github.com/remarkjs/react-markdown/issues/747
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeExternalLinks from "rehype-external-links";
import llmFootnote from "./plugins/footnotePlugin";
import combineFootnotesPlugin from "./plugins/combineFootnotesPlugin";
import filterFootnotesPlugin from "./plugins/filterFootnotesPlugin";

const MemoParagraphMarkdown = React.memo(
  (props: {
    keyString: string;
    markdown: string;
    parentRef: React.RefObject<HTMLDivElement>;
    onFootnotesCollected: (data: string[]) => void; // Function prop with data
  }) => {
    const { state } = useSources(); // Use the context
    const components = chatRenderers(
      state.sources || [],
      state.usedSources,
      props.parentRef,
    );

    // Ref to collect used sources during render
    const usedSourcesRef = useRef<string[]>([]);

    // Collect used sources but don't update the state yet
    const handleFootnotesCollected = (data: string[]) => {
      usedSourcesRef.current = data;
      props.onFootnotesCollected(data); // Pass the data to the parent
    };

    return (
      <ReactMarkdown
        key={props.keyString}
        remarkPlugins={[
          remarkGfm,
          remarkBreaks,
          llmFootnote,
          combineFootnotesPlugin,
          [
            filterFootnotesPlugin,
            {
              allowedFootnotes: state.sources
                .filter((result) => result.considered)
                .map((_, index) => `${index + 1}`),
              callback: handleFootnotesCollected,
            },
          ],
        ]}
        rehypePlugins={[
          [rehypeExternalLinks, { rel: ["noreferrer"], target: "_blank" }],
        ]}
        components={components}
      >
        {props.markdown}
      </ReactMarkdown>
    );
  },
  (prev, next) => prev.keyString === next.keyString,
);

interface ChatMarkdownProps {
  markdown: string;
  requestId: string | null;
}

const ChatMarkdown = React.memo((props: ChatMarkdownProps) => {
  const { dispatch } = useSources(); // Access the dispatch function
  const markdownContainerRef = useRef<HTMLDivElement>(null);

  // Split the markdown into paragraphs
  const paragraphs = React.useMemo(() => {
    return props.markdown
      .split("\n\n") // split by paragraphs
      .map((p) => fixLLMMarkdown(p).trim()) // fix typical LLM markdown issues
      .filter((p) => p.length > 0) // remove empty paragraphs
      .map((content, index) => {
        const id = `${index}_${content.length}_${content.slice(-20)}`; // poor man's unique key
        return { id, content };
      });
  }, [props.markdown]);

  // Ref to track how many paragraphs have been rendered and to collect the data
  const collectedDataRef = useRef<string[]>([]);

  // if id changes, reset the collected data
  useEffect(() => {
    collectedDataRef.current = [];
  }, [props.requestId]);

  // Function to be called when each paragraph finishes rendering
  const handleFootnotesCollected = (data: string[]) => {
    collectedDataRef.current.push(...data); // Collect data from each paragraph
  };

  // Effect to dispatch collected sources after all paragraphs have rendered
  useEffect(() => {
    dispatch({
      type: "SET_USED_SOURCES",
      payload: collectedDataRef.current,
    });
  }, [dispatch, paragraphs]);

  return (
    <ErrorBoundary
      fallback={
        <div className="markdown__error">
          Something went wrong. Please try turning off automatic translation via
          Google Chrome, if you have it enabled!
        </div>
      }
    >
      <div ref={markdownContainerRef} className="markdown">
        {paragraphs.map(({ id, content }) => (
          <MemoParagraphMarkdown
            key={id}
            keyString={`s_${id}`}
            markdown={content}
            parentRef={markdownContainerRef}
            onFootnotesCollected={handleFootnotesCollected} // Pass the function prop
          />
        ))}
      </div>
    </ErrorBoundary>
  );
});

export default ChatMarkdown;
