export function splitArrayByIndexes<T>(
  originalArray: T[],
  includeIndexes: number[]
): { excluded: T[]; included: T[] } {
  const included: T[] = [];
  const excluded: T[] = [];
  const uniqueIndexes = [...new Set(includeIndexes)]; // Remove duplicates

  uniqueIndexes.forEach((index) => {
    if (index >= 1 && index <= originalArray.length) {
      included.push(originalArray[index - 1]);
    }
  });

  originalArray.forEach((element, index) => {
    if (!uniqueIndexes.includes(index + 1)) {
      excluded.push(element);
    }
  });

  return { excluded, included };
}
