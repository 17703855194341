import { FC } from "react";
import classNames from "classnames";

interface PlusMinusIconProps {
  expanded: boolean;
}

export const IconPlusMinus: FC<PlusMinusIconProps> = ({ expanded }) => {
  return (
    <div
      className={classNames("icon-plus-minus", {
        "icon-plus-minus--open": expanded,
      })}
    >
      <div
        className={classNames(
          "icon-plus-minus__line",
          "icon-plus-minus__line--horizontal",
        )}
      />
      <div
        className={classNames(
          "icon-plus-minus__line",
          "icon-plus-minus__line--vertical",
        )}
      />
    </div>
  );
};
