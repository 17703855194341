import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { LinkedTextLine } from "~/src/components/LinkedText/index";
import { ValidationStatus } from "~/src/components/Feedback/index";
import { BACKEND_URL } from "~src/config/env";

type Category = "quality" | "impact";
type CurrentModuleName = "classification";

interface Classification {
  impact?: number;
  quality?: number;
}

interface ClassificationModuleProps {
  requestId: string | null;
  updateModuleStatus: (
    moduleName: CurrentModuleName,
    status: { status: ValidationStatus; sendData?: () => Promise<void> },
  ) => void;
}

interface ClassificationButtonProps {
  cat: Category;
  rating: number;
  text: string;
  classificationData: Classification;
  handleClassification: (cat: Category, rating: number) => void;
}

const ClassificationButton: React.FC<ClassificationButtonProps> = ({
  cat,
  rating,
  text,
  classificationData,
  handleClassification,
}) => (
  <button
    className={classNames("feedback__select", {
      ["feedback__select--active"]: classificationData[cat] === rating,
    })}
    onClick={() => {
      handleClassification(cat, rating);
    }}
  >
    <LinkedTextLine markdown={text} />
  </button>
);

export const ClassificationModule: React.FC<ClassificationModuleProps> = ({
  requestId,
  updateModuleStatus,
}) => {
  const { t } = useTranslation();
  const [classificationData, setClassificationData] = useState<Classification>({
    impact: undefined,
    quality: undefined,
  });

  // Update validation status whenever classificationData changes
  useEffect(() => {
    const isValid =
      classificationData.impact !== undefined &&
      classificationData.quality !== undefined;
    const isInit =
      classificationData.impact === undefined &&
      classificationData.quality === undefined;

    updateModuleStatus("classification", {
      status: isInit
        ? ValidationStatus.INIT
        : isValid
          ? ValidationStatus.VALID
          : ValidationStatus.INVALID,
      sendData: sendClassification,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classificationData]);

  const handleClassification = (cat: Category, rating: number) => {
    // check if the rating is already selected and if so, deselect it
    if (classificationData[cat] === rating) {
      setClassificationData((prevClassification) => ({
        ...prevClassification,
        [cat]: undefined,
      }));
      return;
    } else {
      setClassificationData((prevClassification) => ({
        ...prevClassification,
        [cat]: rating,
      }));
    }
  };

  const sendClassification = async () => {
    const body: Record<string, string | number | undefined> = {
      ...classificationData,
      rID: requestId || "",
    };

    const response = await fetch(`${BACKEND_URL}/classification`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const data = await response.text();
    if (data !== "OK") {
      throw new Error("Classification submission failed");
    }
  };

  return (
    <div>
      <h2 className="feedback__title">{t("feedback.quality.title")}</h2>
      {["3", "2", "1", "4", "0"].map((rating) => (
        <ClassificationButton
          key={rating}
          handleClassification={handleClassification}
          text={t(`feedback.quality.${rating}`)}
          cat={"quality"}
          classificationData={classificationData}
          rating={parseInt(rating)}
        />
      ))}

      <h2 className="feedback__title">{t("feedback.impact.title")}</h2>
      {["1", "2", "3", "4", "5", "0"].map((rating) => (
        <ClassificationButton
          key={rating}
          handleClassification={handleClassification}
          text={t(`feedback.impact.${rating}`)}
          cat={"impact"}
          classificationData={classificationData}
          rating={parseInt(rating)}
        />
      ))}
    </div>
  );
};
