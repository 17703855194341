import { visit } from "unist-util-visit";
import type { Plugin } from "unified";
import type { Node } from "unist";
import { FootnoteLink } from "./FootnoteLink";

interface FilterFootnotesPluginOptions {
  allowedFootnotes: string[];
  callback: (data: string[]) => void;
}

const filterFootnotesPlugin = ({
  allowedFootnotes,
  callback,
}: FilterFootnotesPluginOptions): Plugin => {
  return (tree: Node) => {
    const usedFootnotes: string[] = [];
    const allowedFootnotesSet = new Set(allowedFootnotes);

    visit(
      tree,
      "footnoteLink",
      (node: FootnoteLink, index: number | null, parent: any) => {
        if (index === null || parent === null || !("children" in parent)) {
          return;
        }

        // Filter the footnotes in this node
        const originalValues = node.data.hProperties.value;
        const filteredValues = originalValues.filter((v) =>
          allowedFootnotesSet.has(v),
        );

        if (filteredValues.length === 0) {
          // Remove this node from the tree
          parent.children.splice(index, 1);
        } else {
          // Update the node's value array
          node.data.hProperties.value = filteredValues;

          // Collect used footnotes in order of first appearance
          filteredValues.forEach((v) => {
            if (!usedFootnotes.includes(v)) {
              usedFootnotes.push(v);
            }
          });
        }
      },
    );

    // At the end, call the callback function with the used footnotes
    callback(usedFootnotes);
  };
};

export default filterFootnotesPlugin;
